import Moment from "react-moment";
import placeholder from "../../assets/images/placeholder.png";
import { bgColors } from "../../utils/getColors";
import { FiEdit, FiEdit3 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import formatShort from "../../utils/formatShort";

export default function ProjectWidget({
  project,
  editable,
  handleRank,
  handleEdit,
  handleDate,
  handleDelete,
}: any) {
  return (
    <div className="col-span-12 sm:col-span-6 lg:col-span-4 bg-white  text-xxs mx-4 sm:mx-0">
      {editable && (
        <div className="flex justify-between items-center">
          <button
            onClick={() => handleRank(project)}
            className="flex items-center"
          >
            <span className="mr-2">RANK: {project?.rank || "-"}</span>{" "}
            <FiEdit3 />
          </button>
          <div className="">
            <button
              className="mx-2"
              onClick={() => {
                handleEdit(project);
              }}
            >
              <FiEdit />
            </button>
            <button
              onClick={() => {
                handleDelete(project);
              }}
            >
              <RiDeleteBinLine />
            </button>
          </div>
        </div>
      )}
      <div className="col-span-12 sm:col-span-10 ">
        <div className="bg-gray-100 mb-2">
          <img
            src={project?.thumbnail || placeholder}
            alt=""
            style={{
              backgroundColor: "#F5F5F5",
              width: "100%",
              height: "auto",
              aspectRatio: "1400 / 800",
              objectFit: "cover",
            }}
            className="border-b-2 border"
          />
        </div>

        <div className="pb-1">
          <div className="flex justify-between mb-2">
            <div className="font-bold">
              {project?.title} :{" "}
              <span className="text-gray-500">{project?.client}</span>
            </div>
            <div className="">
              <div
                className="text-xxs sm:text-sm"
                style={{ color: bgColors[project?.status], fontSize: 10 }}
              >
                {project?.status}
              </div>
            </div>
          </div>
          <div className="h-12">
            {project?.summary?.length > 150
              ? project?.summary?.slice(0, 150) + "..."
              : project?.summary}
          </div>
          <a
            href={`${project?.link}`}
            target="_blank"
            className="text-blue-600"
          >
            {project?.link}
          </a>

          <div className="flex justify-between items-start">
            <div className="">
              <div className="flex justify-center items-center">
                <Moment format="MMM YYYY">{project?.startDate}</Moment>
                <span className="mx-2">-</span>
                <Moment format="MMM YYYY">{project?.endDate}</Moment>
                {editable && (
                  <button onClick={() => handleDate(project)} className="mx-1">
                    <FiEdit />
                  </button>
                )}
              </div>
              <div className="text-xs sm:text-sm text-gray-500 mb-2">
                @in-house
              </div>
            </div>
            <div className="">
              <div className="text-xxs">
                Budget: {project?.currency || "USD"}
                {formatShort(project?.budget)}
              </div>
              <div className="text-xxs text-gray-500">
                Sponsor: {project?.sponsor}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
