import { useQuery } from "@tanstack/react-query";
import ProjectWidget from "../../components/project/ProjectWidget";
import { handleFetchProjects } from "../../api/projects";
import Something from "../../components/shared/Something";
import { useState } from "react";
import { FiCheck } from "react-icons/fi";
import { Popover } from "antd";
import { BsCaretDown } from "react-icons/bs";
import formatNumber from "../../utils/formatNumber";
export default function Projects() {
  const today = new Date();
  const [year, setYear] = useState<any>(null);
  const years: any = [
    today.getFullYear(),
    today.getFullYear() - 1,
    today.getFullYear() - 2,
    today.getFullYear() - 3,
    today.getFullYear() - 4,
  ];

  const getTotal = () => {
    let total = 0;

    projects?.forEach((item: any) => {
      item?.projects?.forEach((item: any) => {
        total += item.budget;
      });
    });
    return formatNumber(total);
  };

  const {
    data: projects,
    isFetching,
    refetch,
    isError,
  } = useQuery({
    queryKey: ["projects", year],
    queryFn: () => handleFetchProjects({ year }),
  });

  const clearDate = () => {
    setYear(null);
  };

  const rangeOptions = (
    <div>
      <div className="flex flex-col justify-start items-start w-full hover:bg-gray-100">
        <button
          onClick={() => {
            setYear(null);
          }}
          className="py-1 border-t focus:outline-none w-full flex items-center"
        >
          <div
            className={`w-4 h-4 border border-gray-400 rounded-sm mr-2 flex justify-center items-center ${
              year === null ? "bg-green-500" : ""
            }`}
          >
            <FiCheck color="#ffffff" size={12} />
          </div>
          All Years
        </button>
      </div>
      {years?.map((item: any, index: number) => (
        <div
          className="flex flex-col justify-start items-start w-full hover:bg-gray-100"
          key={index}
        >
          <button
            onClick={() => {
              setYear(item);
            }}
            className="py-1 border-t focus:outline-none w-full flex items-center"
          >
            <div
              className={`w-4 h-4 border border-gray-400 rounded-sm mr-2 flex justify-center items-center ${
                year === item ? "bg-green-500" : ""
              }`}
            >
              <FiCheck color="#ffffff" size={12} />
            </div>
            {item}
          </button>
        </div>
      ))}

      <div className="flex flex-col justify-start items-start w-full border-t-2">
        <button
          onClick={() => clearDate()}
          className="p-2 border-t focus:outline-none w-full flex items-start border mt-2 bg-gray-50 text-red-500 hover:bg-red-100"
        >
          Reset Filters
        </button>
      </div>
    </div>
  );

  return (
    <div className="bg-white">
      <div className="">
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="h-8 w-full bg-blue-100 border-b border-blue-200">
              <div className="flex items-center justify-center">
                <Popover content={rangeOptions} title="" placement="bottomLeft">
                  <button className="flex items-center justify-center cursor-pointer focus:outline-none  py-1 rounded-md">
                    <div className="flex items-center font-bold">
                      <div
                        className={`w-4 h-4 rounded-sm  bg-green-500 mr-2 flex justify-center items-center`}
                      >
                        <FiCheck color="#ffffff" size={12} />
                      </div>
                      {year || "All Years"}
                    </div>
                    <div className="ml-1">
                      <BsCaretDown color="#E8472E" />
                    </div>
                  </button>
                </Popover>
              </div>
            </div>
            <div className="">
              <div className="flex justify-center ">
                <div className="border border-gray-400 my-2 text-xxs mx-4 sm:mx-0 w-full sm:w-1/2">
                  <div className="flex justify-between items-center  border-gray-400 font-bold  bg-blue-200">
                    <div className="p-1">Project</div>
                    <div className="flex">
                      <div className="">Currency</div>
                      <div className="w-32 text-end mx-2">Budget</div>
                    </div>
                  </div>
                  {projects?.map((client: any, index: number) => (
                    <div>
                      <div className="">
                        {client?.projects?.map(
                          (project: any, index: number) => (
                            <div className="flex justify-between items-center border-t border-gray-300">
                              <div className="p-1">{project?.title}</div>
                              <div className="flex">
                                <div className="">{project?.currency}</div>
                                <div className="w-32 text-end mx-2">
                                  {formatNumber(
                                    project?.currency === "TZS"
                                      ? project?.budget / 2500
                                      : project?.budget
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-between items-center border-t-2 border-gray-400">
                    <div className="p-1">TOTAL</div>
                    <div className="flex">
                      <div className=""></div>
                      <div className="w-32 text-end mx-2">{getTotal()}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-4 sm:mx-16">
                {projects?.map((client: any, index: number) => (
                  <div className="mb-8" key={index}>
                    <div className="flex flex-col justify-center items-center py-4 mb-4">
                      <div className="">{year}</div>
                      <div className="text-lg">{client?._id}</div>
                      <div className="text-gray-500 text-xxs mb-2">
                        All projects for {client?._id}
                      </div>
                      <div className="w-16 h-1 bg-fish"></div>
                    </div>

                    <div className="grid grid-cols-12 gap-8">
                      {client?.projects?.map((project: any, index: number) => (
                        <ProjectWidget
                          project={project}
                          key={index}
                          editable={false}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
